/* eslint-disable max-len */
import React from 'react';

/**
 * The `UpdateProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface UpdateProps {
    /**
     * A string representing the CSS class to be applied to the UpdateIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#fff'
     */
    color1?: string;
    /**
     * A number or string representing the height of the UpdateIcon element.
     *
     * @default 22
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Update'
     */
    testId?: string;
    /**
     * A number or string representing the height of the UpdateIcon element.
     *
     * @default 22
     */
    width?: number | string;
}

/**
 * The `Update` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `UpdateProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the UpdateIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the UpdateIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the UpdateIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const UpdateComponent = <Update className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Update = React.forwardRef<SVGSVGElement, UpdateProps>((props, ref) => {
    const {className = '', color1 = '#fff', height = 22, testId = 'Update', width = 22} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 22 22"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M15.65 19.17c.16.33.02.72-.3.88A10.05 10.05 0 0 1 2.75 5.26l-1.51.21a.66.66 0 0 1-.19-1.3l3.86-.55.56 3.85a.66.66 0 0 1-1.3.19l-.26-1.77a8.74 8.74 0 0 0 10.85 12.98.65.65 0 0 1 .88.3m5.84-2.1a.66.66 0 0 0-.74-.55l-1.51.21A10.05 10.05 0 0 0 6.7 1.93a.65.65 0 0 0-.32.87c.16.33.55.47.88.31A8.74 8.74 0 0 1 18.08 16.1l-.26-1.76a.66.66 0 0 0-.74-.55.65.65 0 0 0-.56.74l.56 3.85 3.86-.56c.36-.05.6-.38.55-.74" fill={color1} />
        </svg>
    );
});

Update.displayName = 'Update';