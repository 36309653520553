import styled from 'styled-components';

import {getPLineHeight, getPSize} from './utils';

import type {FontSize} from './utils';


/**
 * The `H1Props` interface defines the props used by the H1 component.
 * The `$isLight` prop allows for customizing the font color of the heading.
 * When set to `true`, the heading will be styled with the secondary font color from the theme, making it appear lighter.
 * If `$isLight` is `false` or not provided, the heading will use the default font color.
 */
interface H1Props {
    /**
     * Determines whether the heading should be displayed in a light style.
     * When set to `true`, the heading will use the secondary font color defined in the theme.
     * If `false` or `undefined`, the heading will use the default font color.
     */
    $isLight?: boolean;
}

/**
 * The H1 component is a styled heading level 1 (`<h1>`) component.
 *
 * @param props          The props of the component.
 * @param props.theme    The theme of the component.
 * @param props.$isLight Determines whether the heading should be displayed in a light style.
 * @returns              A React component.
 *
 * @example
 * ```tsx
 * <H1 $isLight={true}>Hello, World!</H1>
 * ```
 */
export const H1 = styled.h1<H1Props>`
    color: ${({$isLight, theme}) => ($isLight ? theme.colors.textPrimaryInvert : null)};
    font-family: ${({theme}) => theme.fonts.OnAir};
    font-size: 6rem;
    font-weight: normal;
    line-height: 8.4rem;
    margin: 0;
    padding: 0;
`;

/**
 * The `H2Props` interface defines the props used by the H2 component.
 * The `$isLight` prop allows for customizing the font color of the heading.
 * When set to `true`, the heading will be styled with the secondary font color from the theme, making it appear lighter.
 * If `$isLight` is `false` or not provided, the heading will use the default font color.
 */
interface H2Props {
    /**
     * Determines whether the heading should be displayed in a light style.
     * When set to `true`, the heading will use the secondary font color defined in the theme.
     * If `false` or `undefined`, the heading will use the default font color.
     */
    $isLight?: boolean;
}

/**
 * The H2 component is a styled heading level 2 (`<h2>`) component.
 *
 * @param props          The props of the component.
 * @param props.theme    The theme of the component.
 * @param props.$isLight Determines whether the heading should be displayed in a light style.
 * @returns              A React component.
 *
 * @example
 * ```tsx
 * <H2 $isLight={true}>Hello, World!</H2>
 * ```
 */
export const H2 = styled.h2<H2Props>`
    color: ${({$isLight, theme}) => ($isLight ? theme.colors.textPrimaryInvert : null)};
    font-family: ${({theme}) => theme.fonts.OnAir};
    font-size: 4rem;
    font-weight: normal;
    line-height: 5.6rem;
    margin: 0;
    padding: 0;
`;

/**
 * The `H3Props` interface defines the props used by the H3 component.
 * The `$isLight` prop allows for customizing the font color of the heading.
 * When set to `true`, the heading will be styled with the secondary font color from the theme, making it appear lighter.
 * If `$isLight` is `false` or not provided, the heading will use the default font color.
 */
interface H3Props {
    /**
     * Determines whether the heading should be displayed in a light style.
     * When set to `true`, the heading will use the secondary font color defined in the theme.
     * If `false` or `undefined`, the heading will use the default font color.
     */
    $isLight?: boolean;
}

/**
 * The H3 component is a styled heading level 3 (`<h3>`) component.
 *
 * @param props          The props of the component.
 * @param props.theme    The theme of the component.
 * @param props.$isLight Determines whether the heading should be displayed in a light style.
 * @returns              A React component.
 *
 * @example
 * ```tsx
 * <H3 $isLight={true}>Hello, World!</H3>
 * ```
 */
export const H3 = styled.h3<H3Props>`
    color: ${({$isLight, theme}) => ($isLight ? theme.colors.textPrimaryInvert : null)};
    font-family: ${({theme}) => theme.fonts.OnAir};
    font-size: 2.8rem;
    font-weight: normal;
    line-height: 3.9rem;
    margin: 0;
    padding: 0;
`;

/**
 * The `PProps` interface defines the props used by the paragraph component.
 * It includes options for text alignment, styling, and font size, giving flexibility in how the paragraph is displayed.
 */
interface PProps {
    /**
     * Determines the horizontal alignment of the paragraph.
     * - `'center'`: Center alignment.
     * - `'left'`: Left alignment.
     * - `'right'`: Right alignment.
     * If `undefined`, the paragraph will inherit the text alignment from its parent.
     */
    $align?: 'center' | 'left' | 'right';
    /**
     * Determines whether the paragraph should be displayed in a light style.
     * When set to `true`, the paragraph will use the secondary font color defined in the theme.
     * If `false` or `undefined`, the paragraph will use the default font color.
     */
    $isLight?: boolean;
    /**
     * Determines the font size of the paragraph.
     * - `'small'`: Small font size. (1.2rem)
     * - `'medium'`: Medium font size. (1.4rem)
     * - `'large'`: Large font size. (1.6rem)
     * The actual font size is determined by the `getPSize` function.
     * If `undefined`, the paragraph will use the default font size.
     */
    $size?: FontSize;
}

/**
 * The P component is a styled paragraph (`<p>`) component.
 *
 * @param props          The props of the component.
 * @param props.theme    The theme of the component.
 * @param props.$align   Determines the horizontal alignment of the paragraph.
 * @param props.$isLight Determines whether the paragraph should be displayed in a light style.
 * @param props.$size    Determines the font size of the paragraph.
 * @returns              A React component.
 *
 * @example
 * ```tsx
 * <P $isLight={true} $align="center" $size="medium">
 *     Lorem ipsum dolor sit amet, consectetur adipiscing elit.
 * </P>
 * ```
 */
export const P = styled.p<PProps>`
    color: ${({$isLight, theme}) => ($isLight ? theme.colors.textPrimaryInvert : null)};
    font-family: ${({theme}) => theme.fonts.OnAir};
    font-size: ${getPSize};
    line-height: ${getPLineHeight};
    margin: 0;
    padding: 0;
    text-align: ${({$align}) => $align ?? 'inherit' as const};
`;

/**
 * The `toplineProps` interface defines the props used by the topline component.
 * This interface includes a customizable option for controlling the spacing between elements in the component.
 */
interface toplineProps {
    /**
     * Specifies the spacing between elements in the topline component.
     * This value controls the amount of space, typically in pixels or rem units.
     * If not provided, the component will use a default spacing value.
     */
    spacing?: number;
}

/**
 * The Topline component is a styled paragraph (`<p>`) component.
 *
 * @param props         The props of the component.
 * @param props.theme   The theme of the component.
 * @param props.spacing The spacing of the component.
 * @returns             A React component.
 *
 * @example
 * ```tsx
 * <Topline>Board erstellen</Topline>
 * ```
 */
export const Topline = styled(P)<toplineProps>`
    color: ${({theme}) => theme.colors.textSecondary};
    letter-spacing: ${({spacing}) => `${spacing!}rem`};
    text-transform: uppercase;
`;

Topline.defaultProps = {spacing: 0.2};

export const CommentCounter = styled.span`
    align-items: center;
    background-color: ${({theme}) => theme.colors.primary};
    border-radius: 50%;
    color: ${({theme}) => theme.colors.textPrimaryInvert};
    display: inline-flex;
    font-size: 12px;
    font-weight: bold;
    height: 20px;
    justify-content: center;
    width: 20px;
`;