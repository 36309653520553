import type {MouseEvent} from 'react';

import {Color} from '@nfq/colors';
import {spacing} from '@nfq/react-grid';
import styled from 'styled-components';

import {ButtonBase} from 'UI/components/action/buttons/ButtonBase';
import {P} from 'UI/components/layout/Text';

import {useButton} from './useButton';
import type {ButtonIcons} from 'Images/icons';

/**
 * The `ComponentProps` interface defines the properties for the `Button` component.
 * It allows customization of the button's appearance, behavior, and icon, with options for button type, size, variant, and event handling.
 */
interface ComponentProps {
    /**
     * The icon to be displayed on the button.
     * Specify the icon name from the available options.
     */
    icon?: ButtonIcons;
    /**
     * Determines whether the button is disabled.
     * When set to `true`, the button will be non-interactive and visually appear disabled.
     *
     * @default false
     */
    isDisabled?: boolean;
    /**
     * The label text for the button.
     */
    label: string;
    /**
     * Event handler for the button's click event.
     * This function will be called when the button is clicked.
     *
     * @param event The mouse event object associated with the click event.
     */
    onClick?(event: MouseEvent<HTMLButtonElement>): void;
    /**
     * The position of the icon relative to the label text.
     * Available options: `'left'` (default) or `'right'`.
     *
     * @default 'left'
     */
    position?: 'left' | 'right';
    /**
     * The size of the button.
     * Available options: `'large'` (default) or `'small'`.
     *
     * @default 'large'
     */
    size?: 'large' | 'small';
    /**
     * The style variant of the button.
     * This determines the visual style of the button.
     * Available options: `'dark'` (default) or `'light'`.
     *
     * @default 'dark'
     */
    style?: 'dark' | 'light';
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Button'
     */
    testId?: string;
    /**
     * The type of the button.
     * This determines the behavior of the button when clicked.
     * Available options: `'button'` (default), `'reset'`, or `'submit'`.
     *
     * @default 'button'
     */
    type?: 'button' | 'reset' | 'submit';
    /**
     * The variant of the button.
     * This determines the visual variant of the button.
     * Available options: `'primary'` (default) or `'secondary'`.
     *
     * @default 'primary'
     */
    variant?: 'primary' | 'secondary';
}

/**
 * The `Button` component is a customizable button element that supports icons, labels, and different styles and sizes.
 * It handles user interactions through a click event and can be styled with various options, such as `primary`, `secondary`, `dark`, and `light` variants.
 * The button can also display an optional icon and can be disabled if required.
 *
 * @param props            Component props.
 * @param props.icon       The icon of the button. Specify the icon name from the available options.
 * @param props.isDisabled Determines whether the button is disabled. When set to `true`, the button will be non-interactive and visually appear disabled.
 * @param props.label      The label of the button. This is the text that will be displayed on the button.
 * @param props.onClick    The onClick function. This function will be called when the button is clicked.
 * @param props.position   The position of the icon. Available options: `'left'` (default) or `'right'`.
 * @param props.size       The size of the button. Available options: `'large'` (default) or `'small'`.
 * @param props.style      The style of the button. This determines the visual style of the button. Available options: `'dark'` (default) or `'light'`.
 * @param props.testId     The test id. This can be used for identifying the button in tests.
 * @param props.type       The type of the button. This determines the behavior of the button when clicked. Available options: `'button'` (default), `'reset'`, or `'submit'`.
 * @param props.variant    The variant of the button. This determines the visual variant of the button. Available options: `'primary'` (default) or `'secondary'`.
 * @returns The component.
 *
 * @example
 * ```tsx
 * <Button
 *     icon="icon1"
 *     isDisabled={false}
 *     label="Click me"
 *     onClick={handleClick}
 *     position="left"
 *     size="large"
 *     style="dark"
 *     testId="example-button"
 *     type="button"
 *     variant="primary"
 * />
 * ```
 */
const Button = ({
    icon,
    isDisabled = false,
    label,
    onClick = () => {},
    position = 'left',
    size = 'large',
    style = 'dark',
    testId = 'Button',
    type = 'button',
    variant = 'primary'
}: ComponentProps) => {
    const {foregroundColor, Icon, iconPosition, iconSize, textSize} = useButton(style, size, position, icon);

    return (
        <ButtonBase
            isDisabled={isDisabled}
            style={style}
            testId={testId}
            type={type}
            variant={variant}
            onClick={onClick}
        >
            <InnerWrapper $position={iconPosition} $size={size}>
                {Icon && <Icon color1={foregroundColor as string} height={iconSize} testId={`${testId}-icon`} width={iconSize} />}
                <P $size={textSize} data-cy={`${testId}-text`}>
                    {size === 'large' && (
                        <strong><Color $color={foregroundColor}>{label}</Color></strong>
                    )}
                    {size === 'small' && (
                        <Color $color={foregroundColor}>{label}</Color>
                    )}
                </P>
            </InnerWrapper>
        </ButtonBase>
    );
};

Button.displayName = 'Button';

export {Button};

interface InnerWrapperProps {
    $position: 'row-reverse' | 'row';
    $size: 'large' | 'small';
}

const InnerWrapper = styled.span<InnerWrapperProps>`
    align-items: center;
    display: flex;
    flex-direction: ${({$position}) => $position};
    gap: ${({$size, theme}) => ($size === 'large' ? spacing(4, theme) : spacing(2, theme))};
    padding: ${({$size, theme}) => ($size === 'large' ? `${spacing(3, theme)} ${spacing(6, theme)}` : `${spacing(2, theme)} ${spacing(3, theme)}`)};
`;