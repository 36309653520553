/* eslint-disable max-len */
import React from 'react';

/**
 * The `CheckProps` interface defines the properties that can be passed to the `CheckIcon` component.
 * These properties allow customization of the component's CSS class, color, dimensions, and testing identifiers.
 */
interface CheckProps {
    /**
     * A string representing the CSS class to be applied to the CheckIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0050FF'
     */
    color1?: string;
    /**
     * A number or string representing the height of the CheckIcon element.
     *
     * @default 24
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Check'
     */
    testId?: string;
    /**
     * A number or string representing the height of the CheckIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `Check` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `CheckProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the CheckIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the CheckIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the CheckIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const CheckComponent = <Check className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Check = React.forwardRef<SVGSVGElement, CheckProps>((props, ref) => {
    // eslint-disable-next-line @nfq/no-magic-numbers
    const {className = '', color1 = '#0050FF', height = 24, testId = 'Check', width = 24} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M20.53 5.47c.3.3.3.77 0 1.06l-11 11c-.3.3-.77.3-1.06 0l-5-5a.75.75 0 1 1 1.06-1.06L9 15.94 19.47 5.47c.3-.3.77-.3 1.06 0Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Check.displayName = 'Check';