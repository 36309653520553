/* eslint-disable max-len */
import React from 'react';

/**
 * The `AddProps` interface defines the properties that can be passed to the `AddIcon` component.
 * These properties allow customization of the component's CSS class, color, dimensions, and testing identifiers.
 */
interface AddProps {
    /**
     * A string representing the CSS class to be applied to the AddIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0050FF'
     */
    color1?: string;
    /**
     * A number or string representing the height of the AddIcon element.
     *
     * @default 21
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Add'
     */
    testId?: string;
    /**
     * A number or string representing the height of the AddIcon element.
     *
     * @default 21
     */
    width?: number | string;
}

/**
 * The `Add` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `AddProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the AddIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the AddIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the AddIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const AddComponent = <Add className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Add = React.forwardRef<SVGSVGElement, AddProps>((props, ref) => {
    // eslint-disable-next-line @nfq/no-magic-numbers
    const {className = '', color1 = '#0050FF', height = 21, testId = 'Add', width = 21} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 21 21"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M10.5 1.67a8.83 8.83 0 1 0 0 17.66 8.83 8.83 0 0 0 0-17.66ZM.24 10.5a10.26 10.26 0 1 1 20.52 0 10.26 10.26 0 0 1-20.52 0ZM10.5 5.97c.4 0 .72.32.72.71v3.1h3.1a.72.72 0 1 1 0 1.44h-3.1v3.1a.72.72 0 1 1-1.44 0v-3.1h-3.1a.72.72 0 1 1 0-1.44h3.1v-3.1c0-.4.32-.71.72-.71Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Add.displayName = 'Add';