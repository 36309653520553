import {useThemeColors} from '@nfq/colors';

/**
 * A custom React hook for determining the color scheme of buttons based on their variant, style, and border properties.
 * This hook computes different color states (base, hover, pressed) for a button and returns these values.
 * It uses the application's theme colors as a base for calculations and adjusts them based on the provided parameters.
 *
 * @param variant       The variant of the button, either 'primary' or 'secondary'. This affects the base color of the button.
 * @param style         The style context of the button, either 'dark' or 'light'. This influences the color scheme used for the button.
 * @param hasSlimBorder A boolean indicating if the button has a slim border. This affects the active border color of the button.
 * @returns An object containing color properties for the button: `activeBorderColor`, `buttonBaseColor`, `buttonHoverColor`, and `buttonPressedColor`.
 *
 * @example
 * ```tsx
 * const buttonColors = useButtonColors('primary', 'dark', true);
 * ```
 */
export const useButtonColors = (variant: 'primary' | 'secondary', style: 'dark' | 'light', hasSlimBorder: boolean) => {
    const colors = useThemeColors();
    let activeBorderColor: string;
    let buttonBaseColor: string;
    let buttonHoverColor: string;
    let buttonPressedColor: string;

    if (style === 'dark') {
        buttonBaseColor = colors.primary;
        buttonHoverColor = colors.primarySoft;
        buttonPressedColor = colors.primary;
        activeBorderColor = colors.primaryLight;
    } else {
        buttonBaseColor = colors.neutral0;
        buttonHoverColor = colors.neutral20;
        buttonPressedColor = colors.neutral0;
        activeBorderColor = colors.primaryLight;
    }

    if (variant === 'secondary') {
        buttonBaseColor = 'transparent';
    }

    if (hasSlimBorder) {
        activeBorderColor = colors.primaryLight;
    }

    return {
        activeBorderColor,
        buttonBaseColor,
        buttonHoverColor,
        buttonPressedColor
    };
};