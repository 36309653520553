import {authorize} from '@frontify/frontify-authenticator';
import {api, HTTP_METHODS} from '@nfq/typed-next-api';
import {di} from 'react-magnetic-di';

import type {setAuthCookie, unsetAuthCookie} from 'ApiRoutes/authenticate';
import type {LoginAdapter} from 'Domain/adapters/LoginAdapter';
import type {Token} from 'Domain/entities/login/Token';

/**
 * The `LoginService` class provides methods for authentication, login, and logout within the application.
 * It implements the `LoginAdapter` interface and communicates with external authentication services, such as an OAuth server, to handle access tokens.
 * This class is responsible for managing the authentication flow, including retrieving access tokens, sending login requests, and handling logouts.
 */
class LoginService implements LoginAdapter {
    /**
     * Authenticates the user by obtaining an access token from the authorization service.
     * This method calls an authorization function and retrieves a bearer token, specifically the access token, used for future requests.
     *
     * @returns A promise that resolves to the access token as a string. This token can be used to authenticate subsequent API calls.
     */
    async authenticate() {
        di(authorize);

        const authResponse = await authorize({
            clientId: process.env.NEXT_PUBLIC_FRONTIFY_CLIENT_ID,
            domain: process.env.NEXT_PUBLIC_FRONTIFY_DOMAIN,
            scopes: ['basic:read', 'basic:write']
        });

        return authResponse.bearerToken.accessToken;
    }

    /**
     * Sends the obtained token to the server to initiate a login session.
     * This method uses the provided token to authenticate the user by sending it to the backend API, where it is stored as a cookie.
     *
     * @param token The access token retrieved from the authentication process. This token is sent to the server to authenticate the user.
     */
    async login(token: Token) {
        await api<typeof setAuthCookie>('/api/authenticate', {
            body: {accessToken: token},
            method: HTTP_METHODS.POST
        });
    }

    /**
     * Logs the user out by clearing their authentication session.
     * This method sends a request to the backend API to remove the authentication cookie and invalidate the user's session.
     */
    async logout() {
        await api<typeof unsetAuthCookie>('/api/authenticate', {method: HTTP_METHODS.DELETE});
    }
}

export default new LoginService();