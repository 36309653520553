/* eslint-disable max-len */
import React from 'react';

/**
 * The `CompareProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface CompareProps {
    /**
     * A string representing the CSS class to be applied to the CompareIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0050FF'
     */
    color1?: string;
    /**
     * A number or string representing the height of the CompareIcon element.
     *
     * @default 25
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Compare'
     */
    testId?: string;
    /**
     * A number or string representing the height of the CompareIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `Compare` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `CompareProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the CompareIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the CompareIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the CompareIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const CompareComponent = <Compare className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Compare = React.forwardRef<SVGSVGElement, CompareProps>((props, ref) => {
    const {className = '', color1 = '#0050FF', height = 25, testId = 'Compare', width = 24} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 25"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M12.47 2.7a.75.75 0 0 0-1.5 0v1.35H7.18q-.87 0-1.44.03-.6.03-1.1.22c-.53.22-1 .58-1.3 1.06q-.28.5-.32 1.03-.04.5-.03 1.19v10.26q0 .69.03 1.2.04.54.32 1.01c.3.48.77.85 1.3 1.07q.5.2 1.1.22.57.02 1.44.02h3.8v1.7a.75.75 0 1 0 1.5 0v-1.7h.33a.75.75 0 1 0 0-1.5h-.34V5.56h.34a.75.75 0 0 0 0-1.5h-.34zm-1.5 2.85H7.21c-.62 0-1.04 0-1.38.03-.32.02-.5.06-.62.1q-.42.2-.6.48a1 1 0 0 0-.1.38q-.03.34-.02 1.07v10.2q0 .73.03 1.07c.02.23.06.32.1.38q.15.28.6.47.15.09.61.11c.34.02.76.02 1.38.02h3.76zm4.04-.75c0 .42.34.75.75.75h1.3c.57 0 .96 0 1.26.03s.44.05.54.1c.24.1.43.28.53.46.03.06.07.15.1.39s.02.58.02 1.08a.75.75 0 0 0 1.5 0v-.03q0-.7-.03-1.19c-.03-.35-.1-.68-.3-1a2.7 2.7 0 0 0-2.25-1.31q-.54-.04-1.34-.03h-1.33a.75.75 0 0 0-.75.75m0 15.81c0 .42.34.75.75.75h1.33q.8 0 1.34-.02.55-.03 1.04-.23.78-.35 1.22-1.08.26-.48.29-1 .04-.5.03-1.2v-.03a.75.75 0 0 0-1.5 0c0 .51 0 .84-.03 1.09a1 1 0 0 1-.09.39q-.16.28-.53.46c-.1.04-.25.08-.54.1q-.44.02-1.26.02h-1.3a.75.75 0 0 0-.75.75m5.25-9.92a.75.75 0 0 0-.75.75v2.54a.75.75 0 0 0 1.5 0v-2.54a.75.75 0 0 0-.75-.75" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Compare.displayName = 'Compare';