/* eslint-disable max-len */
import React from 'react';

/**
 * The `ChecklistProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ChecklistProps {
    /**
     * A string representing the CSS class to be applied to the ChecklistIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0050FF'
     */
    color1?: string;
    /**
     * A number or string representing the height of the ChecklistIcon element.
     *
     * @default 25
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Checklist'
     */
    testId?: string;
    /**
     * A number or string representing the height of the ChecklistIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `Checklist` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `ChecklistProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the ChecklistIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the ChecklistIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the ChecklistIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const ChecklistComponent = <Checklist className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Checklist = React.forwardRef<SVGSVGElement, ChecklistProps>((props, ref) => {
    const {className = '', color1 = '#0050FF', height = 25, testId = 'Checklist', width = 24} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 25"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M8.77 1.96h6.46q1.21-.01 2 .04c.55.05 1.03.14 1.47.37.7.36 1.28.93 1.64 1.64.23.44.32.92.37 1.47q.05.78.04 2v5.73a.75.75 0 0 1-1.5 0V7.5c0-.85 0-1.45-.04-1.91a2 2 0 0 0-.2-.91 2.3 2.3 0 0 0-.99-.99 2 2 0 0 0-.91-.2c-.46-.04-1.06-.04-1.91-.04H8.8c-.85 0-1.45 0-1.9.04-.46.03-.72.1-.92.2q-.65.34-.98.99c-.1.2-.17.46-.21.91-.04.46-.04 1.06-.04 1.9v10.4c0 .86 0 1.46.04 1.92s.1.71.2.91q.34.65.99.98c.2.1.46.17.91.21.46.04 1.06.04 1.91.04H12a.75.75 0 0 1 0 1.5H8.77q-1.21.01-2-.04a4 4 0 0 1-1.47-.37 4 4 0 0 1-1.64-1.64 4 4 0 0 1-.37-1.47q-.05-.79-.04-2V7.48q-.01-1.22.04-2c.05-.55.14-1.03.37-1.47.36-.7.93-1.28 1.64-1.64.44-.23.92-.32 1.47-.37q.79-.05 2-.04M7.25 7.7c0-.42.34-.75.75-.75h8a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75m0 4c0-.42.34-.75.75-.75h6a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75m0 4c0-.42.34-.75.75-.75h2a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75m14.28.97c.3.3.3.77 0 1.06l-4.5 4.5c-.3.3-.77.3-1.06 0l-2-2a.75.75 0 1 1 1.06-1.06l1.47 1.47 3.97-3.97c.3-.3.77-.3 1.06 0" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Checklist.displayName = 'Checklist';