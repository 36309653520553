import type {ComponentType} from 'react';

import ReactDOMServer from 'react-dom/server';

import type {NormalizeFormData} from 'Domain/entities/dashboard/Inquiry';

/**
 * The `iconAsBackground` function is designed to convert a React component representing an icon into a data URI string that can be used as a background image in CSS.
 * It takes a `ComponentType` representing the icon and an optional props object to be spread into the icon component.
 * This function is crucial for dynamically generating background images in styles, allowing for greater flexibility and customization in styling components.
 *
 * @param Icon  A `ComponentType` representing the React component of the icon to be converted.
 * @param props An optional object representing the props to be spread into the icon component.
 * @returns      A string representing the data URI of the rendered icon, which can be used as a background image in CSS.
 *
 * @example
 * ```tsx
 * const backgroundImage = iconAsBackground(MyIcon, { color: 'red' });
 * ```
 */
export const iconAsBackground = (Icon: ComponentType, props: object = {}): string => `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    // eslint-disable-next-line react/jsx-filename-extension, react/jsx-props-no-spreading
    ReactDOMServer.renderToStaticMarkup(<Icon {...props} />)
)}`;

export const DateFormatter = new Intl.DateTimeFormat('de-DE', {
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    month: '2-digit',
    year: 'numeric'
});

/**
 * Normalizes an object by transforming keys that represent array-like structures into actual arrays.
 * This function scans the object's keys and detects those formatted as `key[index]` (e.g., `tags[0]`, `tags[1]`).
 * It groups such keys into an array under a single key (`tags: ['value1', 'value2']`). Other properties remain unchanged.
 *
 * @param obj The object to be normalized, potentially containing array-like keys.
 * @returns A new object with properly structured arrays where applicable.
 *
 * @example
 * ```tsx
 * const input = { "tags[0]": "value1", "tags[1]": "value2", "name": "example" };
 * const normalized = normalizeObject(input);
 * // Output: { tags: ["value1", "value2"], name: "example" }
 * ```
 */
export function normalizeObject<T extends Record<string, any>>(obj: T) {
    return Object.entries(obj).reduce<NormalizeFormData<T>>((acc, [key, value]) => {
        const match = key.match(/^(.+)\[(\d+)\]$/u);

        if (match) {
            const arrayKey = match[1] as keyof NormalizeFormData<T>;

            // eslint-disable-next-line security/detect-object-injection
            if (!Array.isArray(acc[arrayKey])) {
                // eslint-disable-next-line security/detect-object-injection
                acc[arrayKey] = [] as unknown as NormalizeFormData<T>[keyof NormalizeFormData<T>];
            }

            // eslint-disable-next-line security/detect-object-injection
            (acc[arrayKey] as unknown as string[]).push(value as string);
        } else {
            acc[key as keyof NormalizeFormData<T>] = value;
        }

        return acc;
    }, {} as NormalizeFormData<T>);
}