/* eslint-disable max-len */
import React from 'react';

interface DownloadProps {
    /**
     * A string representing the CSS class to be applied to the DownloadIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0050FF'
     */
    color1?: string;
    /**
     * A number or string representing the height of the DownloadIcon element.
     *
     * @default 24
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Download'
     */
    testId?: string;
    /**
     * A number or string representing the height of the DownloadIcon element.
     *
     * @default 25
     */
    width?: number | string;
}

/**
 * The `Download` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `DownloadProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the DownloadIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the DownloadIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the DownloadIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const DownloadComponent = <Download className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Download = React.forwardRef<SVGSVGElement, DownloadProps>((props, ref) => {
    // eslint-disable-next-line @nfq/no-magic-numbers
    const {className = '', color1 = '#0050FF', height = 24, testId = 'Download', width = 25} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 25 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M4.17 19q0 .42.3.7.31.3.74.3h14.58q.43 0 .74-.3.3-.28.3-.7v-4c0-.55.47-1 1.04-1s1.05.45 1.05 1v4c0 .8-.33 1.56-.92 2.12a3.2 3.2 0 0 1-2.2.88H5.2a3.2 3.2 0 0 1-2.2-.88A3 3 0 0 1 2.08 19v-4c0-.55.47-1 1.04-1s1.05.45 1.05 1z" fill={color1} fillRule="evenodd" />
            <path clipRule="evenodd" d="M6.56 9.3a.97.97 0 0 0 0 1.4l5.2 5c.41.4 1.07.4 1.48 0l5.2-5a.95.95 0 0 0 0-1.4c-.4-.4-1.06-.4-1.47 0l-4.47 4.29-4.47-4.3a1.07 1.07 0 0 0-1.47 0" fill={color1} fillRule="evenodd" />
            <path clipRule="evenodd" d="M12.5 2c-.58 0-1.04.45-1.04 1v12c0 .55.46 1 1.04 1s1.04-.45 1.04-1V3c0-.55-.46-1-1.04-1" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Download.displayName = 'Download';