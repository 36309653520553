/* eslint-disable max-len */
import React from 'react';

/**
 * The `LogOutProps` interface defines the properties that can be passed to the `LogOutIcon` component.
 * These properties allow customization of the component's CSS class, color, dimensions, and testing identifiers.
 */
interface LogOutProps {
    /**
     * A string representing the CSS class to be applied to the LogOutIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0050FF'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LogOutIcon element.
     *
     * @default 24
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'LogOut'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LogOutIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `LogOut` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `LogOutProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the LogOutIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the LogOutIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the LogOutIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const LogOutComponent = <LogOut className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const LogOut = React.forwardRef<SVGSVGElement, LogOutProps>((props, ref) => {
    // eslint-disable-next-line @nfq/no-magic-numbers
    const {className = '', color1 = '#0050FF', height = 24, testId = 'LogOut', width = 24} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h4a1 1 0 1 1 0 2H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h4a1 1 0 0 1 0 2H5Zm10.3 2.3a1 1 0 0 1 1.4 0l5 5a1 1 0 0 1 0 1.4l-5 5a1 1 0 0 1-1.4-1.4l4.29-4.3-4.3-4.3a1 1 0 0 1 0-1.4Z" fill={color1} fillRule="evenodd" />
            <path clipRule="evenodd" d="M8 12a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

LogOut.displayName = 'LogOut';