/* eslint-disable max-len */
import React from 'react';

/**
 * The `HistoryProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface HistoryProps {
    /**
     * A string representing the CSS class to be applied to the HistoryIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0050FF'
     */
    color1?: string;
    /**
     * A number or string representing the height of the HistoryIcon element.
     *
     * @default 24
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'History'
     */
    testId?: string;
    /**
     * A number or string representing the height of the HistoryIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `History` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `HistoryProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the HistoryIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the HistoryIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the HistoryIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const HistoryComponent = <History className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const History = React.forwardRef<SVGSVGElement, HistoryProps>((props, ref) => {
    const {className = '', color1 = '#0050FF', height = 24, testId = 'History', width = 24} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M12 4.5a.75.75 0 0 0-.75.75V12q0 .3.22.53l3 3q.24.22.53.22t.53-.22c.3-.3.3-.75 0-1.05l-2.78-2.78V5.25A.75.75 0 0 0 12 4.5" fill={color1} />
            <path d="M22.76 18.19a.8.8 0 0 0-.86-.64l-1.35.19a10.3 10.3 0 1 0-4.16 3.6.8.8 0 0 0 .37-1.02.8.8 0 0 0-1.01-.37 9 9 0 0 1-3.75.82A8.8 8.8 0 1 1 20.81 12a9 9 0 0 1-1.54 4.99l-.22-1.61a.8.8 0 0 0-.86-.64.75.75 0 0 0-.64.86l.56 4.01 4.02-.6a.7.7 0 0 0 .63-.82" fill={color1} />
        </svg>
    );
});

History.displayName = 'History';