/* eslint-disable max-len */
import React from 'react';

/**
 * The `EditProps` interface defines the properties that can be passed to the `EditIcon` component.
 * These properties allow customization of the component's CSS class, color, dimensions, and testing identifiers.
 */
interface EditProps {
    /**
     * A string representing the CSS class to be applied to the EditIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0050FF'
     */
    color1?: string;
    /**
     * A number or string representing the height of the EditIcon element.
     *
     * @default 24
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Edit'
     */
    testId?: string;
    /**
     * A number or string representing the height of the EditIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `Edit` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `EditProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the EditIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the EditIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the EditIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const EditComponent = <Edit className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Edit = React.forwardRef<SVGSVGElement, EditProps>((props, ref) => {
    // eslint-disable-next-line @nfq/no-magic-numbers
    const {className = '', color1 = '#0050FF', height = 24, testId = 'Edit', width = 24} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M18.2 3.08a2.08 2.08 0 0 1 2.27 3.39l-11.8 11.8-2.93-2.95L17.53 3.53c.2-.2.42-.35.67-.45ZM4.68 16.38l-.5.51-1.11 4.04 4.04-1.1.5-.5-2.93-2.95ZM19 1.42a3.58 3.58 0 0 0-2.53 1.05l-13.5 13.5c-.1.1-.16.2-.2.33l-1.5 5.5a.75.75 0 0 0 .93.92l5.5-1.5c.12-.03.24-.1.33-.19l13.5-13.5A3.58 3.58 0 0 0 19 1.43Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Edit.displayName = 'Edit';